import { Link } from 'gatsby'
import { useLocaleContext } from '../LocaleContext'

const UrlSlug = ({ slug, children }) => {
  const { localeLower } = useLocaleContext()
  const url = slug ? `${localeLower}/${slug}` : null
  if (!url) {
    return null
  }
  return <Link to={url}>{children}</Link>
}

export default UrlSlug
